import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const AffiliateDisclosure = () => (
  <Layout>
    <SEO title="Affiliate Disclosure" />
    <h1>Affiliate Disclosure for CodeSpot</h1>
    <p>CodeSpot is a participant in the Amazon, Udemy, HostGator and Hamecheap Affiliate Programs, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to HostGator.com.
    I’m going to introduce you to products and resources that I recommend. These may be physical products, virtual services, software, or anything else that I feel would help you become a better software engineer.
    Some of the links pointing to those resources are called “affiliate links.” Which means, if you click on the link and make a purchase from that vendor, based on my recommendation, I may earn a commission for that sale.
    Moving forward, please assume that any links included in my content to 3rd party vendors, are affiliate links.</p>
  </Layout>
)

export default AffiliateDisclosure